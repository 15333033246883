<template>
  <div>
      <div class="locale">
          <h2>个人资料</h2>
          
          <ol>
              <li v-for="(item, index) in breadList" :key="item.name">
                <router-link v-if="item.name != name && index != 1" :to="{ path: item.path === '' ? '/' : item.path }">后台</router-link>
              </li>

              <li><router-link to="/">后台</router-link></li>
              <li>个人资料</li>
          </ol>
      </div>

      <a-card title="个人资料">
            <a-descriptions bordered size="small">
              <a-descriptions-item label="工号">{{staff.number}}</a-descriptions-item>
              <a-descriptions-item label="帐号">{{staff.account}}</a-descriptions-item>
              <a-descriptions-item label="密码">*******</a-descriptions-item>

              <a-descriptions-item label="角色">{{staff.role}}</a-descriptions-item>
              <a-descriptions-item label="名字">{{staff.name}}</a-descriptions-item>
              <a-descriptions-item label="职位">{{staff.title}}</a-descriptions-item>

              <a-descriptions-item label="手机号">{{staff.mobile}}</a-descriptions-item>
              <a-descriptions-item label="电子邮箱">{{staff.email}}</a-descriptions-item>
              <a-descriptions-item label="微信号">{{staff.weixin}}</a-descriptions-item>

              <a-descriptions-item label="备注">{{staff.remark}}</a-descriptions-item>

            </a-descriptions>

      </a-card>
    </div>
</template>

<script>
  export default {
    name: 'Profile',
    data () {
      let validator = {
        confirm: (rule, value, callback) => {
          if (value === '') {
            callback(new Error('请再次输入新密码'));
          } else if (value !== this.form.data.password) {
            callback(new Error("两次密码输入不一致"));
          } else {
            callback();
          }
        }
      };
      return {
        name:'',
        breadList:[],
        staff: {},
        form: {
          layout: {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
          },
          rules: {
            current: [{ type: 'string', required: true, message: '当前密码不可为空' }],
            password: [{ type: 'string', required: true, message: '新密码不可为空' }],
            confirm: [{ validator: validator.confirm }],
          },
          data: {
          }
        }
      }
    },
    mounted() {
        this.getBreadcrumb()
      this.$get('/profile').then( res => {
        let ajax = res.data;
        if (ajax.code == 0) {
          this.staff = ajax.data.staff;
          this.form.data = ajax.data.staff;
        }
      })
    },
    methods: {
      onReset() {
        this.$refs.form.resetFields();
      },
      onSubmit() {
        this.$refs['form'].validate(valid => {
          if (valid) {
            this.profile();
          }
        });

      },
      profile (){
        this.$post('/profile', this.form.data).then(res => {
          let ajax = res.data;
          if (ajax.code == 0) {

            //更新缓存
              if (ajax.data.staff) {
                  this.$store.commit('staff', ajax.data.staff);
              }

            setTimeout(()=>{
              this.$router.go(-1);
            }, 1000);

          }
        })
      },
      getBreadcrumb () {
        this.breadList = []
        this.name = this.$route.name
        this.$route.matched.forEach(item => {
          this.breadList.push(item)
        })
      }
    },
    watch: {
      $route () {
        this.getBreadcrumb()
      }
    }
  }
</script>
<style lang="less" scoped>
  .password {
    margin: 80px auto; width: 560px;
  }
  .password h3 {
    /*text-align: center;*/
  }
  .password .box-body {
    padding: 30px;
  }
</style>
